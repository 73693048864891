<template>
  <EventSchedule v-if="event"
        :event="event"
        :selectedMoments="selectedMomentIds"
        :interactiveMoments="interactiveMoments"
        @click="_onClick"
        @info="_onInfo"
    />
</template>

<script>
  import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
    import EventSchedule from '../EventSchedule';
    import EventMomentValidator from '../../../server/lib/EventMomentValidator.cjs';

  @Component({
    components: {
            EventSchedule,
    },
  })
  export default class EventSchedulePicker extends Vue {
    @Prop(Object) event;
        @Prop(Boolean) interactive;
        @Prop(Array) selectedMomentIds;
        @Prop(Array) confirmedMomentIds;

        @Watch('event')
        @Watch('confirmedMomentIds')
        updateValidator() {
            this.validator = new EventMomentValidator(this.event, this.confirmedMomentIds);
        }

        get interactiveMoments() {
            if(!this.validator) {
                this.validator = new EventMomentValidator(this.event, this.confirmedMomentIds);
            }
            return this.interactive ? this.validator.getInteractableMoments(this.selectedMomentIds) : [];
        }

        isSelected(moment) {
            return this.selectedMomentIds.includes(moment.id);
        }

        _onClick(moment) {
            const selectedMoments = [...this.selectedMomentIds];
            if(!this.isSelected(moment)) {
                selectedMoments.push(moment.id);
            } else {
                selectedMoments.splice(selectedMoments.indexOf(moment.id),1);
            }
            this.$emit('change', {
                valid: this.validator.isValidSelection(selectedMoments),
                selectedMomentIds: selectedMoments,
            });
        }

        _onInfo(moment) {
            this.$emit('info', moment);
        }
  }
</script>