import AdminAPI from '../../../api';

export const users = {
    namespaced: true,

    state: {
        users: [],
    },

    getters: {
        list(state) {
            return state.users;
        },
    },

    mutations: {
        _setUsers(state, users) {
            state.users = users;
        },
        _clearAll(state) {
            state.users = [];
        },
    },

    actions: {
        async refresh({ commit }) {
            const users = await AdminAPI.fire('users/getAll');

            commit('_setUsers', users);
        },
        async getUser({ commit }, id) {
            return await AdminAPI.fire('users/getUser', id);
        },
        async editUser({ commit }, data) {
            return await AdminAPI.fire('users/editUser', data);
        },
        async deleteUser({ commit, dispatch }, id) {
            const res = await AdminAPI.fire('users/deleteUser', id);
            dispatch('refresh');
            return res;
        },
    },
};
