import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard';
import Users from './views/Users';
import EditUser from './views/EditUser';
import Login from './views/Login';
import Enroll from './views/Enroll';

import EditEvent from './views/EditEvent';

Vue.use(Router);

export default new Router({
    routes: [

        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
        },

        {
            path: '/users',
            name: 'users',
            component: Users,
        },
        {
            path: '/users/add',
            name: 'addUser',
            component: EditUser,
        },
        {
            path: '/users/:id/edit',
            name: 'editUser',
            component: EditUser,
        },
        {
            path: '/users/:id/delete',
            name: 'deleteUser',
            component: EditUser,
        },

        {
            path: '/login',
            name: 'login',
            component: Login,
            props: true,
        },

        {
            path: '/event/:id/edit',
            name: 'editEvent',
            component: EditEvent,
        },

        {
            path: '/event/:id',
            name: 'enroll',
            component: Enroll,
        },
        {
            path: '/event/:id/enroll',
            name: 'enroll2',
            component: Enroll,
        },
        {
            path: '/event/:id/en',
            name: 'enroll3',
            component: Enroll,
        },
        {
            path: '/event/:id/:enrollment',
            name: 'changeEnrollment',
            component: Enroll,
        },
    ],
});
