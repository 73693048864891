<template>
    <DefaultLayout>
        <Card :title="title" v-if="event">
            <span class="markdown" v-html="renderMarkdown(event.description)" />
            <Form :error="error" v-if="!editMode">
                <FormInput
                    type="name"
                    prepend="Naam"
                    placeholder="Voornaam Achternaam"
                    v-model="name"
                />
                <FormInput
                    type="email"
                    prepend="E-mail"
                    placeholder="email@navara.nl"
                    v-model="email"
                />
                <FormInput
                    type="text"
                    prepend="Allergieen/Wensen"
                    placeholder=""
                    v-model="allergies"
                />
            </Form>
        </Card>
        <Card title="Doe je ook mee?" v-if="!!event">
            <EventSchedulePicker :event="event" :interactive="interactive" :selectedMomentIds="selectedMoments" :confirmedMomentIds="confirmedMomentIds" @change="_onScheduleChange" @info="_onInfo"/>
            <span v-if="event.sessions.some(s => s.required)"><span style="color: red;">*</span> Verplicht</span>
            <Button v-if="!finished && interactive" class="float-right" slot="footer" :title="editMode ? 'Aanpassen' : 'Inschrijven'" icon="save" primary @onPress="_onEnrollPress" :disabled="!validSelection || !email || (!editMode && !name)" />
            <div class="alert alert-danger" role="alert" v-if="error">
                {{error}}
            </div>
            <p v-if="!interactive" class="float-right" slot="footer">Inschrijving Gesloten</p>
        </Card>

        <Modal
            v-if="openInfo"
            :visible="!!openInfo"
            :title="openInfo.session.name"
            @hidden="_onInfo"
            size="m"
        >
            <div v-html="renderMarkdown(openInfo.session.description)" />
        </Modal>
       
        <Modal :visible="showModal" title="Inschrijving verwerkt" @hidden="showModal = false" size="m">
            Bedankt voor het doorgeven van je keuzes, leuk dat je er bij bent!<br/>
            In je mailbox vind je een link om je inschrijving te bekijken of te wijzigen. <br/><br/>

            <small>Protip: je kan ook deze pagina bookmarken of de link bewaren.</small>
            <Button style="margin-right: auto" slot="footer" title="Nog iemand Inschrijven" icon="plus" primary @onPress="_onAddMorePress" />
        </Modal>
    </DefaultLayout>
</template>

<script>
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import DefaultLayout from '../../layouts/Default';
  import Card from '../../components/Card';
  import Button from '../../components/Button';
  import Form from '../../components/Form';
  import FormInput from '../../components/FormInput';
  import EventSchedulePicker from '../../components/EventSchedulePicker';
    import {markdown} from 'markdown';
  import {Getter, Action, Mutation, State} from 'vuex-class';
  import Modal from '../../components/ModalDialog';


  @Component({
    components: {
            EventSchedulePicker,
      DefaultLayout,
      Card,
      Button,
      FormInput,
      Form,
            Modal,
    },
  })
  export default class Enroll extends Vue {
      @Action('api/events/enroll') enroll;
      @Action('api/events/getEvent') getEvent;
      @Action('api/events/getEnrollment') getEnrollment;
      @Action('api/events/setMoments') setMoments;
        @Getter('api/events/event') event;
        @Getter('api/events/moments') selectedMoments;

        error = '';
        validSelection = false;
        confirmedMomentIds = [];

        audience = null;

        name = '';
        email = '';
        allergies = '';

        finished = false;
        editMode = false;
        showModal = false;

        openInfo = null;

        get interactive() {
            return this.event.open;
        }

        get title() {
            return `${this.event.name} - ${new Date(this.event.date).toLocaleDateString()}`;
        }

        renderMarkdown(md) {
            return markdown.toHTML(md);
        }

        async beforeMount() {
            const {email, audience} = this.$route.query;
            if(!this.email && email) this.email = email;
            this.audience = audience || null;

            const { id, enrollment } = this.$route.params;
            await this.getEvent({id, audience: this.audience});
            if(enrollment) {
                const selectedMoments = await this.getEnrollment(enrollment);
                this.confirmedMomentIds = selectedMoments;
                this.setMoments(selectedMoments);
                this.editMode = true;
                this.finished = true;
            }
        }

        back() {
            this.$router.back();
        }

        _onScheduleChange({valid, selectedMomentIds}) {
            this.validSelection = valid;
            this.setMoments(selectedMomentIds);
            this.finished = false;
        }

        async _onEnrollPress() {
            this.error = '';

            try {
                const enrollment = await this.enroll({
                    event: this.event.id,
                    name: this.name,
                    allergies: this.allergies,
                    audience: this.audience,
                    email: this.email,
                    moments: this.selectedMoments,
                    enrollment: this.$route.params.enrollment,
                });
                await this.getEvent({ id: this.event.id, audience: this.audience });
                this.confirmedMomentIds = this.selectedMoments;
                if(!this.editMode) {
                    this.$router.replace({
                        name: 'changeEnrollment',
                        params: { id: this.event.id, enrollment },
                        query: { email: this.email, audience: this.audience || undefined },
                    });
                }
                this.finished = true;
                this.editMode = true;
                this.showModal = true;
            } catch(err) {
                this.error = 'Error: ' + err.message;
            }
        }

        _onAddMorePress() {
            this.$router.push({
                name: 'enroll',
                params: { id: this.event.id },
            });
            this.finished = false;
            this.editMode = false;
            this.showModal = false;
            this.email = '';
            this.name = '';
            this.allergies = '';

            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }

        _onInfo(moment) {
            this.openInfo = this.openInfo ? null : moment;
        }
  }
</script>

<style type="text/css">
 .markdown * {
     max-width: 100%;
 }
</style>
