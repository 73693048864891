<template>
  <img @click="_onPress" :src="src" class="p-0 rounded-circle border" alt="" height="45" width="45"/>
</template>

<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';

  @Component({
    components: {
    },
  })
  export default class Avatar extends Vue {
    @Prop(String) src;

        _onPress() {
            this.$emit('onPress');
        }
  }
</script>