import AdminAPI from '../../../api';

export const events = {
    namespaced: true,

    state: {
        events: [],
        event: null,
        moments: [],
        pendingSwaps: [],
    },

    getters: {
        list(state) {
            return state.events;
        },
        event(state) {
            return state.event;
        },
        moments(state) {
            return state.moments;
        },
    },

    mutations: {
        _setEvents(state, events) {
            state.events = events;
        },
        _setEvent(state, event) {
            state.event = event;
            state.pendingSwaps = [];
        },
        _setMoments(state, moments) {
            state.moments = moments;
        },
        _clearAll(state) {
            state.events = [];
        },
        _updateSession(state, session) {
            state.event.locations.forEach(
                (l) => l.moments.filter((moment) => moment.session.id === session.id)
                    .forEach((moment) => Object.assign(moment.session, session)),
            );
            state.event = {
                ...state.event,
            };
        },
        _updateLocation(state, { id, name, capacity }) {
            const oldLocation = state.event.locations.find((l) => l.id === id);
            const diff = capacity - oldLocation.capacity;
            Object.assign(oldLocation, { name, capacity });
            oldLocation.moments.forEach((m) => { m.remaining += diff; });
            state.event = {
                ...state.event,
            };
        },
        swapMoments(state, { oldMoment, newMoment }) {
            const newLocation = newMoment.location;
            newMoment.location = oldMoment.location;
            oldMoment.location = newLocation;

            oldMoment.remaining = oldMoment.location.capacity - oldMoment.count;
            newMoment.remaining = newMoment.location.capacity - newMoment.count;

            state.pendingSwaps.push([oldMoment.id, newMoment.id]);
        },
        clearSwaps(state) {
            state.pendingSwaps = [];
        },
    },

    actions: {
        async refresh({ commit }) {
            const events = await AdminAPI.fire('events/getAll');

            commit('_setEvents', events);
        },
        async copy(_, {eventId, slug}) {
            return await AdminAPI.fire('events/copy', {eventId, slug});
        },
        async getParticipants({ commit }, { event, moment }) {
            return await AdminAPI.fire('events/getParticipants', { event, moment });
        },
        async getParticipant({ commit }, participant) {
            return await AdminAPI.fire('events/getParticipant', participant);
        },
        async updateParticipant({ commit }, { event, participant, moments }) {
            return await AdminAPI.fire('events/updateParticipant', { event, participant, moments });
        },
        async removeParticipant({ commit }, participant) {
            return await AdminAPI.fire('events/removeParticipant', participant);
        },
        async updateSession({ commit }, session) {
            commit('_updateSession', session);
            return await AdminAPI.fire('events/updateSession', session);
        },
        async updateLocation({ commit }, location) {
            commit('_updateLocation', location);
            return await AdminAPI.fire('events/updateLocation', location);
        },
        async editEvent({ commit }, data) {
            return await AdminAPI.fire('events/editEvent', data);
        },
        async deleteEvent({ commit, dispatch }, id) {
            const res = await AdminAPI.fire('events/deleteEvent', id);
            dispatch('refresh');
            return res;
        },
        async getEvent({ commit }, { id, audience }) {
            const event = await AdminAPI.fire('events/getEvent', { id, audience });
            commit('_setEvent', event);
        },
        async getEnrollment({ commit }, id) {
            return await AdminAPI.fire('events/getEnrollment', id);
        },
        async enroll({ commit, dispatch }, {
            event, moments, email, name, enrollment, allergies, audience,
        }) {
            const res = await AdminAPI.fire('events/enroll', {
                event, moments, email, name, enrollment, allergies, audience,
            });
            return res;
        },
        async confirmSwap({ commit, state }) {
            const swaps = state.pendingSwaps;
            if (swaps.length === 0) return;
            await AdminAPI.fire('events/swapMoments', { eventId: state.event.id, swaps });
            commit('clearSwaps');
        },
        _updateEvent({ commit }, event) {
            if (events.state.event && event.id === events.state.event.id) {
                commit('_setEvent', event);
            }
        },
        setMoments({ commit }, moments) {
            commit('_setMoments', moments);
        },
    },
};
