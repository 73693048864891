<template>
    <Modal :visible="!!moment" :title="moment && moment.session.name" @hidden="_onHide">
      <div v-if="moment && moment.session">
        <FormInput
            type="text"
            prepend="Name"
            v-model="moment.session.name"
        />
        <FormInput
            type="text"
            prepend="Tagline"
            v-model="moment.session.tagline"
        />
        <FormInput
            type="number"
            prepend="Tijdsduur (min)"
            v-model="moment.session.duration"
        />
        <FormInput
            type="text"
            prepend="Audience"
            v-model="moment.session.audience"
        />
        <FormInput
            type="textarea"
            prepend="Description"
            v-model="moment.session.description"
        />
        <span class="markdown" v-html="renderMarkdown(moment.session.description)" />
      </div>
      <!--<Button style="margin-right:auto;" slot="footer" title="Delete" icon="trash" danger @onPress="_onDelete" />-->
      <Button slot="footer" title="Omruilen" icon="sync" warning @onPress="_onSwap" />
      <Button class="float-right" slot="footer" title="Update" icon="save" primary :disabled="!scheduleValid" @onPress="_onSave"/>
    </Modal>
</template>

<script>
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Getter, Action, Mutation} from 'vuex-class';
  import Modal from '../../components/ModalDialog';
  import Button from '../../components/Button';
  import FormInput from '../../components/FormInput';
  import {markdown} from 'markdown';

  @Component({
    components: {
      FormInput,
      Button,
            Modal,
    },
  })
  export default class ViewEditMoment extends Vue {
    @Action('api/events/updateSession') updateSession;
    @Prop() moment;

    get scheduleValid() {
      return this.moment && this.moment.session.name;
    }

    renderMarkdown(md) {
        return markdown.toHTML(md||'');
    }

    _onDelete() {

    }

    _onSwap() {
      this._onHide();
      this.$emit('onSwap', this.moment);
    }

    _onSave() {
      this.updateSession(this.moment.session);
      this.$emit('onHide');
    }

    _onHide() {
      this.$emit('onHide');
    }
  }
</script>