<template>
  <button :type="type" class="btn" :disabled="disabled" v-bind:class="{'btn-primary': primary, 'btn-secondary': !primary, 'btn-sm': small, 'btn-danger': danger, 'btn-warning': warning}" @click.prevent="_onPress">
      <i v-if="icon" class="fas" :class="'fa-'+icon"></i>
      <span v-if="title" class="ml-2">{{title}}</span>
  </button>
</template>

<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';

  @Component({
    components: {
    },
  })
  export default class Button extends Vue {
    @Prop(String) title;
    @Prop(String) icon;
    @Prop(Boolean) primary;
    @Prop(Boolean) small;
    @Prop(Boolean) warning;
    @Prop(Boolean) danger;

    @Prop(Boolean) disabled;

    @Prop(String) type;

        _onPress() {
            this.$emit('onPress');
        }
  }
</script>
