<template>
    <SidebarLayout>
        <Card title="Events">
            <Button slot="header-widget" title="Add" icon="calendar-plus" @onPress="addEvent" small/>
            <TableListView :columns="columns" :rows="items">
                <!-- <Avatar slot="icon" slot-scope="{row}" :src="row.thumb" width="40" height="40" /> -->
                <span slot="actions" slot-scope="{row}" class="text-right model-actions">
                    <Button icon="list" primary small @onPress="openDetail(row)" />
                </span>
            </TableListView>
        </Card>

        <Modal v-if="!!isAddModalOpen" :visible="true" title="Add Event" @hidden="onCloseCopy">
          <p>Please select an event to copy</p>
            <FormInput
                type="select"
                prepend="Events"
                :options="options"
                v-model="selectedEvent"
            />
            <FormInput
                type="input"
                prepend="Slug"
                v-model="slug"
            /><FormInput
                type="text"
                prepend="Enrollment URL"
                :disabled="true"
                :value="fullUrl({name: 'enroll', params: {id: slug}})"
            />
            <Button type="submit" class="float-right" slot="footer" title="Copy" icon="save" primary @onPress="copyEvent"/>
        </Modal>
    </SidebarLayout>
</template>

<script>
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import Modal from '../../components/ModalDialog';
  import FormInput from '../../components/FormInput';
  import SidebarLayout from '../../layouts/Sidebar';
  import TableListView from '../../components/TableListView';
  import Card from '../../components/Card';
  import Avatar from '../../components/Avatar';
  import Button from '../../components/Button';
  import {Getter, Action, Mutation} from 'vuex-class';

    const COLUMNS = [
        {
            name: 'Name',
            id: 'name'
        },
  ];

  @Component({
    components: {
      SidebarLayout,
      TableListView,
      Card,
      Avatar,
      Button,
      Modal,
      FormInput,
    },
  })
  export default class ViewDashboard extends Vue {
        columns = COLUMNS;

        isAddModalOpen = false;
        selectedEvent = null;

        slug = null;

        @Getter('api/events/list') items;
        @Action('api/events/refresh') refresh;
        @Action('api/events/copy') _copyEvent;

        @Watch('items')
        _updateSelected() {
            this.selectedEvent = this.items[0].id;
        }

        @Watch('selectedEvent')
        _updateSlug() {
            this.slug = this.selectedEvent+'-new';
        }

        get options() {
            return Object.fromEntries(this.items.map(item => [item.id, item.name]));
        }

        beforeMount() {
            this.refresh();
        }

        openDetail(item) {
            this.$router.push({name: 'editEvent', params: {id: item.id}});
        }

        addEvent() {
            this.isAddModalOpen = !this.isAddModalOpen;
        }

        async copyEvent() {
            this.isAddModalOpen = !this.isAddModalOpen;
            await this._copyEvent({eventId: this.selectedEvent, slug: this.slug});
            await this.refresh();
        }

        onCloseCopy() {
            this.isAddModalOpen = false;
        }

        fullUrl(url) {
            const location = this.$router.resolve(url).href
            return new URL(location, window.location.href).href;
        }
  }
</script>

<style type="text/css">
    .model-actions {
        width: 300px;
    }
    .model-link {
        width: 250px;
    }
</style>
