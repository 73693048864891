<template>
    <Modal :visible="!!location" :title="location && location.name" @hidden="_onHide">
      <div v-if="location">
        <FormInput
            type="text"
            prepend="Name"
            v-model="location.name"
        />
        <FormInput
            v-if="!hasPlenairLocation"
            type="checkbox"
            prepend="Plenair"
            v-model="plenair"
        />
        <FormInput
            v-if="!plenair"
            type="checkbox"
            prepend="Unlimited"
            v-model="unlimited"
        />
        <FormInput
            v-if="!unlimited && !plenair"
            type="number"
            prepend="Capacity"
            :min="1"
            v-model="location.capacity"
        />
      </div>
      <!--<Button style="margin-right:auto;" slot="footer" title="Delete" icon="trash" danger @onPress="_onDelete" />-->
      <Button class="float-right" slot="footer" title="Update" icon="save" primary :disabled="!location || !location.name" @onPress="_onSave"/>
    </Modal>
</template>

<script>
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Getter, Action, Mutation} from 'vuex-class';
  import Modal from '../../components/ModalDialog';
  import Button from '../../components/Button';
  import FormInput from '../../components/FormInput';
  import {markdown} from 'markdown';

  @Component({
    components: {
      FormInput,
      Button,
            Modal,
    },
  })
  export default class ViewEditLocation extends Vue {
    @Action('api/events/updateLocation') updateLocation;
    @Prop() location;
    @Prop() event;

    get hasPlenairLocation() {
      return this.event.locations.some((l) => l.capacity === -1 && l.id !== this.location.id);
    }


    get plenair() {
      return this.location && this.location.capacity === -1;
    }
    set plenair(value) {
      this.location.capacity = value ? -1 : 0;
    }

    get unlimited() {
      return this.location && this.location.capacity === 0;
    }

    set unlimited(value) {
      this.location.capacity = value ? 0 : 1;
    }

    renderMarkdown(md) {
        return markdown.toHTML(md||'');
    }

    _onDelete() {

    }

    _onSave() {
      this.updateLocation(this.location);
      this.$emit('onHide');
    }

    _onHide() {
      this.$emit('onHide');
    }
  }
</script>