<template>
    <Modal :visible="!!moment" :title="moment && moment.session.name" @hidden="_onHide">
      <div v-if="moment && moment.session">
        Hier kun je deze sessie van locatie wissellen met een andere sessie.
        <FormInput
            type="select"
            prepend="Target"
            v-model="selectedMoment"
            :options="moments"
        />
      </div>
      <Button class="float-right" slot="footer" title="Update" icon="save" primary :disabled="!scheduleValid" @onPress="_onSave"/>
    </Modal>
</template>

<script>
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {Getter, Action, Mutation} from 'vuex-class';
  import Modal from '../../components/ModalDialog';
  import Button from '../../components/Button';
  import FormInput from '../../components/FormInput';

  @Component({
    components: {
      FormInput,
      Button,
            Modal,
    },
  })
  export default class SwapMoment extends Vue {
    @Action('api/events/swapMoments') swapMoments;
    @Prop() moment;
    @Prop() event;

    selectedMoment = null;

    @Watch('moment')
    _updateSelectedMoment() {
      this.selectedMoment = this.moment && this.moment.id;
    }

    selectedMomentFits(selected = this.getMoment()) {
      return selected.count <= this.moment.location.capacity && this.moment.count <= selected.location.capacity;
    }

    getMoment(id = this.selectedMoment) {
      return this.event.locations.map(l => l.moments.find(m => m.id === id)).find(Boolean);
    }

    get scheduleValid() {
      return this.moment && this.moment.session.name && this.moment.id !== this.selectedMoment && this.selectedMomentFits();
    }

    get moments() {
      const moments = this.event.locations.flatMap(l => l.moments);
      const availableMoments = moments.filter(m => m.start === this.moment.start && m.session.duration === this.moment.session.duration).filter(m => this.selectedMomentFits(m));
      return Object.fromEntries(availableMoments.map((moment) => [moment.id, moment.session.name]));
    }

    _onSave() {
      this.$emit('onSwap', this.getMoment(), this.getMoment(this.moment.id));
      this._onHide();
    }

    _onHide() {
      this.$emit('onHide');
    }
  }
</script>