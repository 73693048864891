<template>
  <AuthLayout>
      <div class="row pt-3">
          <div class="col-sm">
            <slot/>
        </div>
          <div class="col-md-4">
              <Card title="Tasks" v-if="$slots.tasks">
                  <slot name="tasks"/>
            </Card>
          </div>
    </div>
        <ModalDialog title="Error" :visible="!!errorMsg" @hidden="clearError()">
            {{errorMsg}}
        </ModalDialog>
  </AuthLayout>
</template>

<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { Getter, Action, Mutation } from 'vuex-class';
  import AuthLayout from '../Auth';
  import Card from '../../components/Card';
    import ModalDialog from '../../components/ModalDialog';

    @Component({
        components: {
            AuthLayout,
            ModalDialog,
            Card,
        },
    })
    export default class SidebarLayout extends Vue {
        state = {
        };

        errorMsg = null;
        clearError() {
            this.errorMsg = null;
        }
        showError(e) {
            console.error(e);
            this.errorMsg = e.message;
        }
        
    }
</script>