<template>
    <div class="card mb-3" role="document">
        <div class="card-header">
            <slot name="header">
                <div v-if="$slots['header-widget']" class="float-right flex gap-1">
                    <slot name="header-widget"/>
                </div>
                <h5 class="align-middle mt-2">{{title}}</h5>
            </slot>
        </div>
        <div class="card-body">
            <slot/>
    </div>
        <div class="card-footer" v-if="$slots.footer">
            <slot name="footer"/>
    </div>
  </div>
</template>

<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';

  @Component({
    components: {
    },
  })
  export default class Card extends Vue {
    @Prop(String) title;
  }
</script>