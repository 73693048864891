class EventMomentValidator {
    constructor(event, confirmedMoments) {
        this.event = event;
        this.confirmedMoments = confirmedMoments || [];
        this.moments = event.locations
            .filter((l) => l.capacity >= 0)
            .flatMap((l) => l.moments.map((m) => ({
                ...m,
                sid: m.session.id,
                session: m.session,
                moment: m,
                duration: m.session.duration,
                end: m.session.duration + m.start,
                required: m.session.required,
                location: l,
            })));
        this.requiredSessions = this.event.sessions
            .filter((s) => s.required);
    }

    conflicts(m1, m2) {
        if (m1.id === m2.id) return false;
        return (m1.start >= m2.start && m1.start < m2.end)
            || (m1.end > m2.start && m1.end <= m2.end);
    }

    getUnpickedMandatorySessionIds(selectedMomentIds) {
        const selectedMoments = this.moments.filter((m) => selectedMomentIds.includes(m.id));
        const selectedSessions = selectedMoments.map((m) => m.sid);

        return this.requiredSessions
            .filter((rs) => !selectedSessions.includes(rs.id))
            .map((s) => s.id);
    }

    getRemainingPossibilities(selectedMomentIds) {
        const selectedMoments = this.moments.filter((m) => selectedMomentIds.includes(m.id));
        const selectedSessions = selectedMoments.map((m) => m.sid);

        let available = this.moments
            .filter((m) => m.location.capacity === 0 || m.remaining > 0 || (m.remaining === 0 && this.confirmedMoments.includes(m.id)))
            .filter((m) => !selectedSessions.includes(m.sid))
            .filter((m) => !selectedMoments.some((sm) => this.conflicts(sm, m)));

        const unpickedMandatorySessionIds = this.getUnpickedMandatorySessionIds(selectedMomentIds);

        const unpickedMandatoryMoments = available.filter((m) => unpickedMandatorySessionIds.includes(m.sid));

        available = available
            .filter((m) => !unpickedMandatorySessionIds
                .some((msid) => unpickedMandatoryMoments
                    .filter((umm) => umm.sid === msid)
                    .every((umm) => this.conflicts(m, umm))));

        // get all moments
        // get picked moments
        // get picked sessions
        // filter all moments
        // - session cannot be already picked
        // - moment cannot conflict with picked option
        // - moment cannot conflict with last remaining required moment
        // return selected and available ones

        return available.map((m) => m.id);
    }

    getInteractableMoments(selectedMomentIds) {
        const available = this.getRemainingPossibilities(selectedMomentIds);
        return [...selectedMomentIds, ...available];
    }

    isValidSelection(selectedMomentIds) {
        const selectedMoments = this.moments.filter((m) => selectedMomentIds.includes(m.id));
        const unavailableSelections = selectedMoments
            .filter((m) => m.location.capacity !== 0)
            .filter((m) => m.remaining < 0 || (m.remaining === 0 && !this.confirmedMoments.includes(m.id)));

        return unavailableSelections.length === 0
            && this.getRemainingPossibilities(selectedMomentIds).length === 0
            && this.getUnpickedMandatorySessionIds(selectedMomentIds).length === 0;
    }
}

module.exports = EventMomentValidator;
