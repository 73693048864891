<template>
    <AuthLayout>
        <Card title="Event">
            <Button slot="header-widget" title="Back" icon="caret-left" @onPress="back" small/>

            <Form v-if="event" @submit="saveEvent" :error="error">
                <FormInput
                    type="text"
                    prepend="Enrollment URL"
                    :disabled="true"
                    :value="fullUrl({name: 'enroll', params: {id: event.id}})"
                />
                <FormInput
                    v-for="aud of audiences"
                    :key="aud+'url'"
                    type="text"
                    :prepend="`Enrollment URL (${aud})`"
                    :disabled="true"
                    :value="fullUrl({name: 'enroll', params: {id: event.id}, query: {audience: aud}})"
                />
                <FormInput
                    type="name"
                    prepend="Name"
                    v-model="event.name"
                />
                <FormInput
                    type="datetime-local"
                    prepend="Date"
                    v-model="humanDate"
                />
                <FormInput
                    type="checkbox"
                    prepend="Enrollment Open"
                    v-model="event.open"
                />
                <FormInput
                    type="input"
                    prepend="Sendgrid Template ID"
                    v-model="event.emailTemplate"
                />
                <FormInput
                    type="textarea"
                    prepend="Description"
                    v-model="event.description"
                />
                <span class="markdown" v-html="renderMarkdown(event.description)" />
            </Form>

            <Button class="float-right" slot="footer" title="Save" icon="save" primary @onPress="saveEvent"/>
        </Card>
        <Card title="Schedule">
            <Button slot="header-widget" icon="edit" title="Edit" @onPress="toggleEdit" small v-if="!editable"/>
            <Button slot="header-widget" icon="save" title="Save" @onPress="saveEdit" small primary v-if="editable"/>
            <EventSchedule
                :event="event"
                :selectedMoments="moment ? [moment.id] : []"
                :editable="editable"
                @change="_onChangeEvent"
                @click="_onScheduleChange"
                v-if="event"
            />
        </Card>
        <Card :title="`${moment && moment.session.name || 'All'} - Participants (${participants.length})`">
          <Button v-if="participants.length" slot="header-widget" icon="random" @onPress="onCrossRefModalOpen"/>
          <Button v-if="participants.length" slot="header-widget" icon="download" @onPress="downloadParticipants"/>
            <TableListView :columns="participantColumns" :rows="participants" v-if="participants.length">
                <Avatar slot="icon" slot-scope="{row}" :src="row.avatar" width="40" height="40" />
                <span slot="actions" slot-scope="{row}" class="text-right model-actions">
                    <Button icon="list" primary small @onPress="openDetail(row)" />
                </span>
            </TableListView>
            <p v-else>No participants</p>
        </Card>
        <Modal v-if="event && participant" :visible="!!participant" :title="participant && participant.name" @hidden="onParticipantHidden">
          <FormInput
              type="text"
              prepend="Change URL"
              :disabled="true"
              :value="participant.link"
          />
          <FormInput
              type="text"
              prepend="Allergieen"
              :disabled="true"
              :value="participant.allergies"
          />
          <FormInput
              type="text"
              prepend="Audience"
              :disabled="true"
              :value="participant.audience"
          />
          <EventSchedulePicker :event="event" :selectedMomentIds="moments" :confirmedMomentIds="confirmedMoments" :interactive="true" @change="_onParticipantScheduleChange"/>
          <Button style="margin-right:auto;" slot="footer" title="Remove Participant" icon="trash" danger @onPress="_onDeleteParticipant" />
          <Button class="float-right" slot="footer" title="Update" icon="save" primary :disabled="!scheduleValid" @onPress="_onUpdateParticipant"/>
        </Modal>
        <Modal v-if="event && isCrossRefModalOpen" :visible="!!isCrossRefModalOpen" :title="'Cross-ref participants for ' + (this.moment ? this.moment.session.name : this.event.name)" @hidden="onCrossRefModalClose">
          <p>Enter a comma-separated list of e-mail addresses to cross-reference with participants</p>
            <FormInput
                type="textarea"
                prepend="E-mail addresses"
                value=""
                @input="onCrossRefInputChange"
            />
            <Button type="submit" class="float-right" slot="footer" title="Download cross-ref list" icon="save" primary @onPress="crossReferenceParticipants"/>
        </Modal>
        <ChangeMomentModal :moment="editingMoment" :event="event" @onHide="_onEditMomentHide" @onSwap="_onSwapMoment"/>
        <ChangeLocationModal :location="editingLocation" :event="event" @onHide="_onEditLocationHide"/>
        <SwapMomentModal :moment="swapMoment" :event="event" @onHide="_onSwapMomentHide"  @onSwap="_onSwapMomentConfirm"/>
    </AuthLayout>
</template>

<script>
  import {Component, Vue, Watch} from 'vue-property-decorator';
  import TableListView from '../../components/TableListView';
  import AuthLayout from '../../layouts/Auth';
  import Card from '../../components/Card';
  import Button from '../../components/Button';
  import Avatar from '../../components/Avatar';
  import Form from '../../components/Form';
  import Modal from '../../components/ModalDialog';
  import FormInput from '../../components/FormInput';
  import EventSchedule from '../../components/EventSchedule';
  import EventSchedulePicker from '../../components/EventSchedulePicker';
    import ChangeMomentModal from './ChangeMoment';
    import ChangeLocationModal from './ChangeLocation';
    import SwapMomentModal from './SwapMoment';
  import {Getter, Action, Mutation} from 'vuex-class';
    import {markdown} from 'markdown';

    const COLUMNS = [
        {
            name: 'Name',
            id: 'name'
        },
        {
            name: 'Email',
            id: 'email'
        },
        {
            name: 'Audience',
            id: 'audience'
        },
        {
            name: 'Enrollment',
            id: 'createdAt'
        },
  ];

  @Component({
    components: {
            ChangeMomentModal,
            ChangeLocationModal,
            SwapMomentModal,
            TableListView,
            EventSchedule,
            EventSchedulePicker,
      AuthLayout,
      Card,
      Button,
      FormInput,
      Form,
            Modal,
            Avatar,
    },
  })
  export default class ViewEditEvent extends Vue {
      @Action('api/events/editEvent') editEvent;
      @Action('api/events/getEvent') getEvent;
      @Action('api/events/getParticipants') getParticipants;
      @Action('api/events/getParticipant') getParticipant;
      @Action('api/events/updateParticipant') updateParticipant;
      @Action('api/events/removeParticipant') removeParticipant;
      @Mutation('api/events/swapMoments') localSwap;
      @Action('api/events/confirmSwap') confirmSwap;

        @Getter('api/events/event') event;

        editingMoment = null;
        editingLocation = null;

        swapMoment = null;

        editable = false;

        error = '';
        validSelection = false;
        moment = null;
        participants = [];

        participant = null;
        moments = [];
        confirmedMoments = [];
        scheduleValid = false;

        participantColumns = COLUMNS;

        allMoments = [];
        isCrossRefModalOpen = false;
        crossRefInput = '';

        async refresh() {
            if(this.$route.params.id) {
                await this.getEvent({id: this.$route.params.id});
            }
        }

        async beforeMount() {
            await this.refresh();
        }

        toggleEdit() {
            this.editable = !this.editable;
        }

        async saveEdit() {
            this.toggleEdit();
            await this.confirmSwap();
            await this.refresh();
        }

        _onChangeEvent(type, data) {
            switch(type) {
                case 'event':
                    this.editingMoment = JSON.parse(JSON.stringify(data));
                    break;
                case 'location':
                    this.editingLocation = { id: data.id, name: data.name, capacity: data.capacity };
                    break;
                default:
                    alert(type, data);
            }
        }

        _onEditMomentHide() {
            this.editingMoment = null;
        }

        _onEditLocationHide() {
            this.editingLocation = null;
        }

        renderMarkdown(md) {
            return markdown.toHTML(md||'');
        }

        fullUrl(url) {
            const location = this.$router.resolve(url).href;
            return new URL(location, window.location.href).href;
        }

        back() {
            this.$router.back();
        }

        get audiences() {
            if(!this.event || !this.event.sessions) return [];
            return new Set(this.event.sessions.map(s => s && s.audience).filter(Boolean));
        }

        get humanDate() {
            const d = new Date(this.event.date);
            return new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString().slice(0,16)
        }

        set humanDate(value) {
            this.event.date = new Date(value.slice(0,16)).toISOString();
        }

        @Watch('event')
        async loadParticipants() {
            const participants = await this.getParticipants({ event: this.event.id, moment: this.moment && this.moment.id });

            this.allMoments = this.event.locations
                .flatMap(l => l.moments);

            this.participants = participants.map(p => ({
                ...p,
                ...Object.fromEntries(p.moments
                    .map(mid => this.allMoments.find(m => m.id === mid))
                    .map(m => ['moment'+m.start, m.session.name])
                )
            }))
        }

        _onScheduleChange(moment) {
            if(this.moment === moment) {
                this.moment = null;
            } else {
                this.moment = moment;
            }
            this.loadParticipants();
        }

        async saveEvent() {
            this.error = '';

            try {
                await this.editEvent(this.event);
                this.$router.push({name:'dashboard'});
            } catch(err) {
                this.error = err.message;
            }
        }
        async openDetail(participant) {
            this.scheduleValid = false;
            this.participant = participant;
            this.participant = await this.getParticipant(participant.id);
            this.moments = this.participant.moments;
            this.confirmedMoments = this.participant.moments;
        }

        onParticipantHidden() {
            this.participant = null;
            this.moments = [];
            this.confirmedMoments = [];
        }

        onCrossRefModalOpen() {
          this.isCrossRefModalOpen = true;
        }
        onCrossRefModalClose() {
          this.isCrossRefModalOpen = false;
        }
        onCrossRefInputChange(value){
          this.crossRefInput = value;
        }

        _onParticipantScheduleChange({selectedMomentIds, valid}) {
            this.moments = selectedMomentIds;
            this.scheduleValid = valid;
        }

        async _onUpdateParticipant() {
            await this.updateParticipant({
                event: this.event.id,
                participant: this.participant.id,
                moments: this.moments,
            });
            this.onParticipantHidden();
            await this.refresh();
            this.loadParticipants();
        }

        async _onDeleteParticipant() {
            if (confirm('Are you sure?')) {
                await this.removeParticipant(this.participant.id);
                this.onParticipantHidden();
                await this.refresh();
                this.loadParticipants();
            }
        }

        downloadTsv(data, columns){
            const download = data.map(p => columns.map(c => p[c]));
            download.unshift(columns.map(c => c));

            const blobData = download.map(row => row.join('\t')).join('\r\n');
            const file = new Blob([blobData], {type: 'application/octet-stream'});

            const a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.target = '_blank';
            a.download = [
              this.event.name,
              this.moment && this.moment.session.name || 'all',
              this.moment && this.moment.start,
              new Date().toISOString().split('.')[0].replace('T','_'),
              'participants.tsv'
            ].map(a => String(a).replace(/[^a-z0-9\._]/gi, ''))
                .join('_')
                .toLowerCase();
            document.body.appendChild(a);

            a.click();
        }

        crossReferenceParticipants() {
            const crossRefList = this.crossRefInput.replace(/(,|;)\s*$/, "").split(';').flatMap(e => e.split(','));

            const externalParticipants = this.participants.reduce((acc, p) => {
                return crossRefList.includes(p.email) ?  acc :  [...acc, { name: p.name, email: p.email, status: 'external'}];
            }, []);
            const data = [...externalParticipants, ...crossRefList.map((email) => {
                const participant = this.participants.find((p) => p.email === email);
                const status = participant ? 'registered' : 'not registered';
                const name = participant ? participant.name : '-';
                return {name, email, status};
            })];

            const columns = ['name', 'email', 'status'];
            this.downloadTsv(data, columns);
        }

        downloadParticipants() {
            const momentStarts = Array.from(new Set(this.allMoments.map(m => m.start)))
                .sort((a,b) => a-b)
                .map(start => 'moment'+start);
            const columns = ['name', 'email', 'audience', 'allergies', 'createdAt', 'link', , ...momentStarts];

            this.downloadTsv(this.participants, columns);
        }

        _onSwapMomentHide() {
            this.swapMoment = null;
        }

        _onSwapMoment(moment) {
            this.swapMoment = moment;
        }

        _onSwapMomentConfirm(oldMoment, newMoment) {
            this.localSwap({oldMoment, newMoment});
            this._onSwapMomentHide();
        }

  }
</script>

<style type="text/css">
 .markdown * {
     max-width: 100%;
 }
</style>
