<template>
  <DefaultLayout>
    <slot v-if="isLoggedIn"/>
  </DefaultLayout>
</template>

<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { Getter, Action, Mutation } from 'vuex-class';
  import DefaultLayout from '../Default/index.vue';

    @Component({
        components: {
            DefaultLayout
        },
    })
    export default class AuthLayout extends Vue {
        @Getter('api/isLoggedIn') _isLoggedIn;

        @Prop
        get isLoggedIn() {
            if (!this._isLoggedIn) {
                this.$router.push({name: 'login', params: {redirectTo: this.$route.fullPath}});
            }
            return this._isLoggedIn;
        }
    }
</script>